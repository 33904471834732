<template>
  <div class="grid h-screen" style="grid-template-rows: 55px 1fr 55px;">
    <header class="border-b bg-gray-100">
      <h2 class="text-center h-full uppercase font-semibold py-4">Profile</h2>
    </header>
    <main class="text-center overflow-auto pb-10">
      <div class="mt-12"><Icon name="person.svg" class="w-12 h-12"/></div>
      <p class="my-4">{{userProfile.email}}</p>
      <button class="btn-white" @click="onSignOut">Sign Out</button>
    </main>
    <AppFooter/>
  </div>
</template>


<script>
import AppFooter from '@/components/AppFooter'
import Icon from '@/components/Icon'
import { mapState } from 'vuex'

export default {
  name: 'Account',
  computed: {
    ...mapState(['userProfile']),
  },
  components: {
    AppFooter,
    Icon
  },
  methods: {
    onSignOut (){
      this.$store.dispatch('logout')
    }
  }
}
</script>
